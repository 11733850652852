"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import SampleVideoItem from "@/components/SampleVideo/SampleVideo";
import DPIcon from "@/components/DPIcon/DPIcon";
import _e from "@/helpers/texts";
import { linkUrl } from "@/helpers";
import { usePathname } from "next/navigation";
import { LinkType } from "@/components/FastMenu/FastMenu";
import JsonLd from "@/components/JsonLd/JsonLd";
import SideBar from "@/components/SideBar/SideBar";

export default function SampleVideos({ data, mostViewed }: {
  data: Record<string, any>,
  mostViewed: Record<string, any>[],
}) {
  
  const pathname = usePathname();

  const links = (data.view?.categories ?? []).map((item: Record<string,any>) => {
    return {
      id: item.id,
      url: `${data.slug}/${item.slug}`,
      caption: item.caption,
      target: '_self',
      sublink: item?.video_sub_categories?.length ? item.video_sub_categories.map((sub: Record<string,any>) => {
        return {
          id: sub.id,
          url: `${data.slug}/${item.slug}/${sub.slug}`,
          caption: sub.caption,
          target: 'self',
        };
      }) : undefined,
    };
  }) as LinkType[];

  links.unshift({
    id: 'root_0_link',
    url: `${data.slug}`,
    caption: _e('Tümü'),
    target: '_self',
    exact: true,
  });

  return (
    <div className="container">
      <div className="grid grid-cols-1 lg:grid-cols-11 gap-x-[30px] mb-10">
        <SideBar className="bg-white shadow-default col-span-3 h-fit rounded-[6px] hidden lg:block" list={links} />

        <div className="col-span-1 lg:col-span-8">
          <h2 className="text-[#1d252d] font-semibold text-[28px] leading-10 mb-[30px]">
            { _e('Doping Hafıza') } <span className="text-lava">{ data.view.top_video_caption ?? '' }</span>
          </h2>
          <VideoPlayer
            src={linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', data.view.top_video_link) ?? ''}
            poster={linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', data.view?.top_video_poster) ?? ''}
          />
          <JsonLd data={{
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": data.view.top_video_caption,
            "description": data.view.top_video_caption,
            "thumbnailUrl": linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', data.view?.top_video_poster) ?? '',
            "uploadDate": data.publishedAt,
            "contentUrl": linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', data.view.top_video_link) ?? '',
          }} />

          <h3 className="text-gunmetal font-semibold text-[28px] leading-10 mb-[30px] mt-[50px]">
            { data.view?.most_viewed_block_caption ?? ''}
          </h3>
          <div className="lg:bg-white grid grid-cols-1 lg:grid-cols-2 gap-x-[30px] lg:p-10 ">
            {
              mostViewed?.map((item: Record<string,any>) => (
                <SampleVideoItem
                  key={item.id}
                  src={linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', item.video_url) ?? ''}
                  poster={linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', item.video_poster_url) ?? ''}
                  title={item?.caption ?? ''}
                  videoDesc={ item?.video_sub_category?.caption ?? item?.video_sub_category?.video_category?.caption ?? _e('Tanıtım Videoları') }
                  videoTime={ item?.duration ? Math.floor((item.duration ?? 0) / 60) + ':' + ((item.duration ?? 0) % 60).toString().padStart(2, '0') : '' }
                  slug={item?.video_sub_category?.slug && item.video_sub_category?.video_category?.slug && item?.slug ? `${pathname}/${item.video_sub_category.video_category.slug}/${item.video_sub_category.slug}/${item.slug}` : undefined}
                />
              ))
            }
            <JsonLd data={{
              "@context": "https://schema.org",
              "@type": "VideoGallery",
              "itemListElement": mostViewed?.map((item: Record<string,any>, index: number) => {
                return {
                  "@type": "VideoObject",
                  "position": index + 1,
                  "name": item?.caption,
                  "description": item?.subcap,
                  "thumbnailUrl": linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/files/videogorsel/', item.video_poster_url) ?? '',
                  "duration": item?.duration ? 'PT' + Math.floor((item.duration ?? 0) / 60) + 'M' + ((item.duration ?? 0) % 60).toString().padStart(2, '0') + 'S' : '',
                  "contentUrl": linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', item.video_url) ?? '',
                  "uploadDate": item.publishedAt,
                };
              })
            }} />

          </div>
        </div>
      </div>
      
      {/* Video Categories */}
      {
        data.view?.categories?.filter((item: Record<string,any>) => item?.video_sub_categories?.length > 1)?.map((item: Record<string,any>) => (
          <div key={item.id} className="lg:mt-20 mb-10">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-lava font-bold text-[40px]">{ item?.caption ? item.caption.split('Örnek Videolar')[0] : '' }</h2>
                <h3 className="text-charcoal text-[28px] font-medium mb-5">
                  { _e('Örnek Videolarımız') }
                </h3>
              </div>
              <div className="items-center gap-[30px] hidden lg:flex">
                <button className={`my-custom-prev-${item.id}`}>
                  <DPIcon
                    icon="arrow-circle-left"
                    className="text-cadet-gray hover:text-lava transition-all text-[40px]"
                  />
                </button>
                <button className={`my-custom-next-${item.id}`}>
                  <DPIcon
                    icon="arrow-circle-right"
                    className="text-cadet-gray hover:text-lava transition-all text-[40px]"
                  />
                </button>
              </div>
            </div>
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              modules={[Navigation]}
              className="mySwiper"
              navigation={{
                nextEl: `.my-custom-next-${item.id}`,
                prevEl: `.my-custom-prev-${item.id}`,
              }}
            >
              {
                item?.video_sub_categories?.map((sub: Record<string,any>) => (
                  <SwiperSlide key={item.id + '_' + sub.id} className="rounded-[10px] w-full bg-transparent max-w-[350px] max-h-[350px]">
                    <SampleVideoItem
                      poster={linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/files/ornekvideo/', sub.card_image_url) ?? ''}
                      title={sub.caption}
                      videoTime={`${sub.videos?.count ?? 0} örnek video`}
                      categories="Örnek Videolar"
                      slug={`${pathname}/${item.slug}/${sub.slug}`}
                    />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        ))
      }
    </div>
  );
}
